import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-flow: column;
    align-items: flex-start;
    margin: 0;

    div.dropdown {
      margin: 0 0 25px;
    }
  }
`;

export const HeaderContent = styled.div`
  margin: 0 0 0 10px;

  h1 {
    font-size: 32px;
    font-weight: 800;
  }

  p {
    color: var(--secondary-label-color);
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

type StatisticsContainerProps = { sections: number };
export const StatisticsContainer = styled.div<StatisticsContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  margin: 20px 0;

  & > div {
    width: calc(100% / ${props => props.sections || 4});
  }

  @media (max-width: 768px) {
    margin: 0;
    flex-flow: column nowrap;

    & > div {
      margin: 20px 0;
      width: 100%;
    }
  }
`;

export const Statistic = styled.div`
  text-align: center;

  span {
    font-size: 32px;
    font-weight: 600;

    .small {
      font-size: 20px;
    }
  }

  &.green span { color: var(--devo-green); }
  &.red span { color: var(--devo-red); }
  

  p {
    color: var(--secondary-label-color);
    margin: 0;
  }
`;

export const ModuleContainer = styled.div`
  padding: 35px 25px;
  margin-bottom: 35px;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: var(--devo-shadow);
  overflow: hidden;

  & > h2 {
    margin: 15px 20px 0;
  }

  & > p {
    margin: 10px 20px 30px;
    color: var(--secondary-label-color);
  }

  & > .graph {
    height: calc(100% - 100px);

    .recharts-tooltip-label {
      font-weight: 600;
      font-size: 20px;
    }
  }

  & > .table-responsive {
    padding: 0 10px;
  }

  .product {
    margin: 0 -20px;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
    margin-bottom: 25px;

    & > .graph {
      height: calc(100% - 150px);
    }
  }
`;

export const ModuleHeader = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 15px 20px 0
    }
    p {
      margin: 10px 20px 30px;
      color: var(--secondary-label-color);
    }
  `,
  Left: styled.div`
    
  `,
  Right: styled.div`
  
  `,
}

export const ModuleContent = styled.div`
  padding: 0 20px;

  & > p {
    color: var(--secondary-label-color);
    margin: 0;
  }
`;

export const ExpandModule = styled.div`
  margin: 10px 0;
  color: var(--blue);
  text-align: center;

  * {
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
  }
`;

export const Postcode = {
  Row: styled.div`
    margin-bottom: 20px;
  `,
}