import React from 'react';
import {
  Container,
  Wrapper,
  Icon,
  Content,
  Title,
  Message,
} from './Error.style';
import parseError from 'helper/error';

type ErrorDisplayProps = {
  error?: any;
  title?: string;
  emoji?: string;
  style?: any;
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
  error,
  title = "Something went wrong!",
  emoji = '⚠️',
  style
}) => {

  if (!error) return <></>;

  return (
    <Container>
      <Wrapper style={style}>
        <Icon><span>{emoji}</span></Icon>
        <Content>
          { title && <Title>{title}</Title>}
          <Message>{parseError(error)}</Message>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default ErrorDisplay;
