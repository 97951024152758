import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 95%;
  max-width: 700px;
  margin: 20px auto;
  background-color: #f7f7f7;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  padding: 20px;
  display: inline-block;
  vertical-align: top;

  span {
    font-size: 30px;
  }
`;

export const Content = styled.div`
  display: inline-block;
  padding: 20px 0;
  width: 70%;
`;

export const Title = styled.h3`
  font-size: 22px;
  margin-bottom: 5px;
`;

export const Message = styled.p`
  margin: 0;
`;
