import { Order } from "models/Order";

export type TimeScaleOption = 'LAST_7_DAYS' | 'PREVIOUS_WEEK' | 'LAST_30_DAYS' | 'PREVIOUS_MONTH' | 'YEAR_TO_DATE' | 'LAST_YEAR' | 'PAST_YEAR';
export type TimeSelectOption =  'NOW' | 
                                'ONE_WEEK_AGO' | 'ONE_MONTH_AGO' | 'ONE_YEAR_AGO' |
                                'START_OF_YEAR' |
                                'LAST_SUNDAY' | 'LAST_MONDAY_BEFORE_SUNDAY' | 
                                'LAST_START_OF_MONTH' | 'LAST_END_OF_MONTH' |
                                'LAST_START_OF_YEAR' | 'LAST_END_OF_YEAR';
export const ALL_TIME_SCALES: TimeScaleOption[] = ['LAST_7_DAYS', 'PREVIOUS_WEEK', 'LAST_30_DAYS', 'PREVIOUS_MONTH', 'YEAR_TO_DATE', 'LAST_YEAR', 'PAST_YEAR'];
export const ALL_TIME_SELECTIONS: TimeSelectOption[] = ['NOW', 'ONE_WEEK_AGO', 'ONE_MONTH_AGO', 'ONE_YEAR_AGO', 'START_OF_YEAR', 'LAST_SUNDAY', 'LAST_MONDAY_BEFORE_SUNDAY', 'LAST_START_OF_MONTH', 'LAST_END_OF_MONTH', 'LAST_START_OF_YEAR', 'LAST_END_OF_YEAR'];

export const getDateMinusDays = (days: number, from: Date = new Date()): Date => {
  var date = new Date(from.getTime());
  var pastDate = date.getDate() - days;
  date.setDate(pastDate);
  return normalizeDate(date);
};

export const getLastDayOfWeek = (from: Date, target: 1 | 2 | 3 | 4 | 5 | 6 | 7): Date => {
  const date = new Date(from.getTime());
  date.setDate(date.getDate() - (date.getDay() == target ? 7 : (date.getDay() + (7 - target)) % 7 ))
  return date;
};

export const getDate = (which: TimeSelectOption): Date => {
  if (!which) return null;
  const now = normalizeDate(new Date());
  switch (which) {
    case 'NOW':                       return now;
    case 'ONE_WEEK_AGO':              return getDateMinusDays(7);
    case 'ONE_MONTH_AGO':             return getDateMinusDays(30);
    case 'ONE_YEAR_AGO':              return getDateMinusDays(365);
    case 'LAST_SUNDAY':               return getLastDayOfWeek(now, 7);
    case 'LAST_MONDAY_BEFORE_SUNDAY': return getLastDayOfWeek(getLastDayOfWeek(now, 7), 1);
    case 'LAST_START_OF_MONTH':       return new Date(now.getFullYear(), now.getMonth()-1, 1);
    case 'LAST_END_OF_MONTH':         return new Date(now.getFullYear(), now.getMonth(), 0);
    case 'START_OF_YEAR':             return new Date(now.getFullYear(), 0, 1);
    case 'LAST_START_OF_YEAR':        return new Date(now.getFullYear()-1, 0, 1);
    case 'LAST_END_OF_YEAR':          return new Date(now.getFullYear(), 0, 0);
  }
};

export const getAllDatesBetween = (from: Date, to: Date) => {
  var result = [to];
  var i = 1;
  while (result[result.length-1] > from) {
    const d = getDateMinusDays(i, to);
    result.push(d);
    i += 1;
  }
  return result;
};

export const timeScaleName = (timeScale: TimeScaleOption): string => {
  switch (timeScale) {
    case 'LAST_7_DAYS':     return "Last 7 days";
    case 'PREVIOUS_WEEK':   return "Previous week";
    case 'LAST_30_DAYS':    return "Last 30 days";
    case 'PREVIOUS_MONTH':  return "Previous month";
    case 'YEAR_TO_DATE':    return "Year to date";
    case 'LAST_YEAR':       return "Last year";
    case 'PAST_YEAR':       return "Past year";
  }
};

export const timeScaleDescription = (timeScale: TimeScaleOption): string => {
  switch (timeScale) {
    case 'LAST_7_DAYS':     return "over the last 7 days";
    case 'PREVIOUS_WEEK':   return "over the previous week";
    case 'LAST_30_DAYS':    return "over the last 30 days";
    case 'PREVIOUS_MONTH':  return "over the previous month";
    case 'YEAR_TO_DATE':    return "for the year to date";
    case 'LAST_YEAR':       return "for the last year";
    case 'PAST_YEAR':       return "for the past year";
  }
};

export const timeSelectionForScale = (timeScale: TimeScaleOption): TimeSelectOption[] => {
  switch (timeScale) {
    case 'LAST_7_DAYS':     return ["NOW", "ONE_WEEK_AGO"];
    case 'PREVIOUS_WEEK':   return ["LAST_SUNDAY", "LAST_MONDAY_BEFORE_SUNDAY"];
    case 'LAST_30_DAYS':    return ["NOW", "ONE_MONTH_AGO"];
    case 'PREVIOUS_MONTH':  return ["LAST_END_OF_MONTH", "LAST_START_OF_MONTH"];
    case 'YEAR_TO_DATE':    return ["NOW", "START_OF_YEAR"];
    case 'LAST_YEAR':       return ["LAST_END_OF_YEAR", "LAST_START_OF_YEAR"];
    case 'PAST_YEAR':       return ["NOW", "ONE_YEAR_AGO"];
  }
};

export const isSameDate = (a, b): boolean => {
  return Math.abs(a - b) < (1000 * 3600 * 24) && a.getDay() === b.getDay();
};

const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const daysArr = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
export const formatDateStr = (date: Date, options: { weekday?: boolean } = {}): string => {
  if (!date) return null;
  const { weekday = true } = options;
  var wd = daysArr[date.getDay()];
  var d = date.getDate();
  var m = monthArr[date.getMonth()];
  var y = date.getFullYear();

  if (weekday) {
    return `${wd}, ${d} ${m}`;
  } else {
    return `${d} ${m}`;
  }
};

export const getMinimumDateFromOrders = (orders: Order[]): Date => {
  return normalizeDate(new Date(Math.min.apply(Math, orders.map(x => x.createdAt))));
};

export const getMaximumDateFromOrders = (orders: Order[]): Date => {
  return normalizeDate(new Date(Math.max.apply(Math, orders.map(x => x.createdAt))));
};

export const normalizeDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};