import gql from 'graphql-tag';
import { getAuthToken } from 'helper/user';
import { OrderBasketItem } from 'models/Order';

export const GET_ORDERS = gql`
  query getOrders($shopId: String, $fromDate: Int, $toDate: Int) {
    response(shopId: $shopId, fromDate: $fromDate, toDate: $toDate)
    @rest(type: "Order[]",
          method: "GET",
          path: "/order/paginated?shopUuid={args.shopId}&limit=1000&from={args.fromDate}&to={args.toDate}",
          endpoint: "os") {
      orders
      results
    }
  }
`;

// path: "/order/paginated?shopUuid={args.shopId}&orderStatuses=STARTED,REQUESTING_RIDER,RIDER_TO_SHOP,FLEET_ERROR",
const LIVE_ORDERS_STATUSES = "CONFIRMED,REQUESTING_RIDER,FLEET_ERROR,RIDER_TO_SHOP,RIDER_TO_USER,DELIVERED";
export const GET_LIVE_ORDERS = gql`
  query getOrders($shopId: String, $fromDate: Int) {
    response(shopId: $shopId, fromDate: $fromDate)
    @rest(type: "Order[]",
          method: "GET",
          path: "/order/paginated?shopUuid={args.shopId}&offset=0&limit=25&from={args.fromDate}&orderStatuses=${LIVE_ORDERS_STATUSES}",
          endpoint: "os") {
      orders
      results
    }
  }
`;

const PAST_ORDERS_STATUSES = "CREATED,CHECKOUT,CONFIRMED,STARTED,SCHEDULED,REQUESTING_RIDER,RIDER_TO_SHOP,RIDER_TO_USER,DELIVERED,COMPLETED,FLEET_ERROR,CANCELED";
export const GET_PAST_ORDERS = gql`
  query getOrders($shopId: String, $fromDate: Int, $offset: Int, $limit: Int) {
    response(shopId: $shopId, fromDate: $fromDate, offset: $offset, limit: $limit)
    @rest(type: "Order[]",
          method: "GET",
          path: "/order/paginated?shopUuid={args.shopId}&offset={args.offset}&limit={args.limit}&from={args.fromDate}&orderStatuses=${PAST_ORDERS_STATUSES}",
          endpoint: "os") {
      orders
      results
    }
  }
`;

export const GET_ORDER = gql`
  query getFullOrder($orderId: string) {
    order(orderId: $orderId) @rest(type: "Order", path: "/order/{args.orderId}", endpoint: "os") {
      addressLine1
      addressLine2
      basket
      basketPrice
      city
      completedAt
      createdAt
      creditUsed
      deliveredAt
      deliveryFee
      deliveryMessage
      devoAppEnum
      discounts
      expectedBy
      id
      isNew
      jobDelay
      jobId
      originalBasketPrice
      originalDeliveryFee
      originalTotalPrice
      postcode
      promotionCode
      scheduledAt
      serviceFee
      shopUuid
      smallBasketFee
      status
      totalPrice
      userUuid
      uuid
    }
  }
`;

export const GET_JOB = gql`
  query getJob($jobId: string) {
    response(jobId: $jobId) @rest(type: "OrderModel", path: "/job/{args.jobId}", endpoint: "fs") {
      createdAt
      deliveryStatus
      driverId
      driverLatitude
      driverLongitude
      driverName
      driverPhone
      driverPhoto
      driverTransportType
      durationEstimate
      durationEstimateDelta
      finishedAt
      id
      jobDelay
      jobStatus
      orderUuid
      pickedAt
      trackingUrl
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: String) {
    response(userId: $userId)
    @rest(type: "User",
          method: "GET",
          path: "/user/{args.userId}",
          endpoint: "as") {
      addressLine1
      addressLine2
      city
      deliveryMessage
      email
      firstName
      joinedDate
      lastName
      lockedUntil
      loginAttempts
      over18
      phoneNumber
      postcode
      shopUuid
      uuid
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($orderId: String) {
    response(orderId: $orderId)
    @rest(type: "OrderModel", method: "PUT", path: "/order/{args.orderId}", endpoint: "os") {
      addressLine1
      addressLine2
      basket
      basketPrice
      city
      completedAt
      createdAt
      creditUsed
      deliveredAt
      deliveryFee
      deliveryMessage
      devoAppEnum
      discounts
      expectedBy
      id
      isNew
      jobDelay
      jobId
      originalBasketPrice
      originalDeliveryFee
      originalTotalPrice
      postcode
      promotionCode
      scheduledAt
      serviceFee
      shopUuid
      smallBasketFee
      status
      totalPrice
      userUuid
      uuid
    }
  }
`;

export const UPDATE_ORDER_PRODUCT = gql`
  mutation updateOrder($itemId: String) {
    response(itemId: $itemId)
    @rest(type: "OrderBasketItem", method: "PUT", path: "/basket/{args.itemId}", endpoint: "os") {
      available
      devoMarkup
      devoPrice
      orderUuid
      priceMarked
      productUpc
      replacementDevoPrice
      replacementName
      replacementShopPrice
      replacementUpc
      replacementvatRate
      shopCommission
      shopPrice
      uuid
      vatRate
    }
  }
`;

export const updateOrderProduct = (itemId: string, item: OrderBasketItem): Promise<string> => {
  return new Promise((resolve, reject) => {
    var url = new URL(`${process.env.API_ORDER_SERVICE}/basket/${itemId}`);

    const params: any = { isAvailable: item.available ? "true" : "false" }
    if (item.replacementName) params.replacementName = item.replacementName;
    if (item.replacementShopPrice) params.replacementShopPrice = item.replacementShopPrice?.toString();
    if (item.replacementUpc) params.replacementUpc = item.replacementUpc?.toString();
    if (item.replacementvatRate) params.replacementVatCode = item.replacementvatRate?.toString();

    url.search = new URLSearchParams(params).toString();
    fetch(url.toString(), {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      }
    })
    .then(res => res.json())
    .then(resolve)
    .catch(err => reject(err));
  });
};
