import React, { useEffect } from 'react';
import protectedRoute from 'helper/protectedRoute';
import Dashboard from 'containers/Dashboard/Dashboard';
import useShopContext from 'contexts/shop/shop.context';
import useAuth from 'contexts/auth/auth.context';
import { DeviceType } from 'helper/useDeviceType';
import { SEO } from 'components/SEO/SEO';
import Mixpanel from 'helper/Mixpanel';
import { Container, Wrapper } from 'containers/AppLayout/AppLayout.style';
import { Header, HeaderContent } from 'components/Dashboard/Dashboard.style';

type HomeProps = {
  deviceType: DeviceType;
};

const Home: React.FC<HomeProps> = ({ deviceType }) => {
  const { authState } = useAuth();
  const { shopState } = useShopContext();
  const { user } = authState;

  useEffect(() => Mixpanel.track('dashboard'), []);

  if (!user || !shopState) {
    return <>
      <SEO title={`Dashboard | Devo Partner Centre`} />
      <Container>
        <Wrapper>
          <Header>
            <HeaderContent>
              <h1>Hey 👋</h1>
              <p>Something went wrong loading your shop.</p>
              <p>Please try again or contact support.</p>
            </HeaderContent>
          </Header>
        </Wrapper>
      </Container>
    </>
  }

  return (
    <>
      <SEO title={`${shopState.name} | Devo Partner Centre`} />
      <Dashboard />
    </>
  )
}

export default protectedRoute(Home);