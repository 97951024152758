import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useAuth from 'contexts/auth/auth.context';
import useShopContext from 'contexts/shop/shop.context';
import { useQuery } from '@apollo/client';
import {
  Container,
  Wrapper,
} from 'containers/AppLayout/AppLayout.style';
import {
  Header,
  HeaderContent,
} from 'components/Dashboard/Dashboard.style';
import { Dropdown } from 'react-bootstrap';
import { DropdownButton } from 'components/ProductsToolbar/Toolbar.style';
import Loader from 'components/Loader/Loader';

const DashboardContent = dynamic(
  () => import('components/Dashboard/DashboardContent'),
  { loading: () => <Loader style={{ margin: '0 auto' }} />}
);

import { TimeScaleOption, ALL_TIME_SCALES, getDate, timeSelectionForScale, getMinimumDateFromOrders, getMaximumDateFromOrders, timeScaleDescription, timeScaleName } from 'helper/dashboard/time';
import { Order } from 'models/Order';
import { GET_ORDERS } from 'api/orders';
import ErrorDisplay from 'components/Error/Error';
import Mixpanel from 'helper/Mixpanel';

const Dashboard: React.FC = () => {
  const { authState } = useAuth();
  const { user } = authState;
  const { shopState } = useShopContext();

  const [timeScale, setTimeScale] = useState<TimeScaleOption>('LAST_7_DAYS');
  const [orders, setOrders] = useState([]);

  const timeSelection = timeSelectionForScale(timeScale);
  const toDate = getDate(timeSelection[0]) || getMaximumDateFromOrders(orders);
  const fromDate = getDate(timeSelection[1]) || getMinimumDateFromOrders(orders);

  const { data, loading, error } = useQuery(GET_ORDERS, {
    variables: {
      shopId: shopState.uuid,
      fromDate: fromDate.getTime(),
      toDate: toDate.getTime(),
    },
  });

  useEffect(() => {
    if (!data?.response?.orders) return;

    const processed: Order[] = data.response.orders.map(x => {
      x.createdAt = new Date(x.createdAt);
      x.deliveredAt = new Date(x.deliveredAt);
      x.completedAt = new Date(x.completedAt);
      return x
    });

    setOrders(processed);
  }, [data, timeScale]);

  const handleTimeScaleSelect = (timeScale: TimeScaleOption) => {
    Mixpanel.track('dashboard_select_time_scale', { time_scale: timeScale });
    setTimeScale(timeScale);
  };

  const loaded = !loading && data;

  return (
    <Container>
      <Wrapper>
        <Header>
          <HeaderContent>
            <h1>Hey, {user.firstName} 👋</h1>
            {data && <p>
              Here's {shopState.name}'s performance {timeScaleDescription(timeScale)}.
            </p>}
          </HeaderContent>

          {loaded &&
            <DropdownButton>
              <Dropdown>
                <Dropdown.Toggle>
                  {timeScaleName(timeScale)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {ALL_TIME_SCALES.map((item, index) =>
                    <Dropdown.Item key={index} onClick={e => handleTimeScaleSelect(item)}>
                      {timeScaleName(item)}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </DropdownButton>
          }
        </Header>

        {loading && 
          <Loader style={{ margin: '0 auto 20px' }} />
        }

        {error &&
          <ErrorDisplay error={error} />
        }

        {loaded &&
          <DashboardContent 
            orders={orders}
            timeScale={timeScale}
            fromDate={fromDate}
            toDate={toDate}
          />
        }
      </Wrapper>
    </Container>
  );
};

export default Dashboard;